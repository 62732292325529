import React, { createContext, useReducer } from "react"
import { markers } from "../config/markers"

const initialState = {
  currentPosition: markers[0],
  activeMarker: markers[0],
  setCurrentPosition: (item: any) => {
    console.log(item)
  },
  setActiveMarker: (item: any) => {
    console.log(item)
  },
}

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_ACTIVE_MARKER":
      // We have to allow it to reopen the sidebar on the same marker after closing it
      // if (action.payload.imageKey === state.activeMarker.imageKey) {
      //   return state
      // }
      return {
        ...state,
        activeMarker: action.payload,
      }
    case "SET_CURRENT_POSITION":
      if (action.payload.imageKey === state.currentPosition.imageKey) {
        return state
      }
      return {
        ...state,
        currentPosition: action.payload,
      }
    default:
      return state
  }
}

export const MapStateContext = createContext(initialState)

export const MapStateProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  // Actions for changing state
  function setCurrentPosition(item) {
    dispatch({
      type: "SET_CURRENT_POSITION",
      payload: item,
    })
  }

  function setActiveMarker(item) {
    dispatch({
      type: "SET_ACTIVE_MARKER",
      payload: item,
    })
  }

  return (
    <MapStateContext.Provider
      value={{
        currentPosition: state.currentPosition,
        setCurrentPosition,
        activeMarker: state.activeMarker,
        setActiveMarker,
      }}
    >
      {children}
    </MapStateContext.Provider>
  )
}
