import styled from '@emotion/styled'
import { getSize } from './'

type GapProps = {
  size?: string
}

const Gap = styled.div<GapProps>`
  width: ${(props: GapProps) => {
    if (!props.size) {
      return
    }

    return getSize(props.size)
  }};

  height: ${(props: GapProps) => {
    if (!props.size) {
      return
    }

    return getSize(props.size)
  }};
`

export default Gap
