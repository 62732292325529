import styled from "@emotion/styled"

type PaddingProps = {
  size?: string
}

const Padding = styled.div<PaddingProps>`
  padding: ${props => props.size || "0"};
`

export default Padding
