import React, { useRef, useState, useEffect, useContext } from "react"
import propTypes from "prop-types"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import mapboxgl from "!mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import { useMatomo } from "@datapunt/matomo-tracker-react"

import { breakpoints } from "../../config"
import { markers, sequences } from "../../config/markers"
import { MapStateContext } from "../../contexts/map-state"

const icons = {
  audio:
    '<svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7 4a3 3 0 016 0v4a3 3 0 11-6 0V4zm4 10.93A7.001 7.001 0 0017 8a1 1 0 10-2 0A5 5 0 015 8a1 1 0 00-2 0 7.001 7.001 0 006 6.93V17H6a1 1 0 100 2h8a1 1 0 100-2h-3v-2.07z" clip-rule="evenodd"></path></svg>',
  video:
    '<svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path></svg>',
  info: '<svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>',
  location:
    '<svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd"></path></svg>',
}

const MapContainer = styled.div(
  ({ enabled }) => css`
    display: ${enabled ? "block" : "none"};
    z-index: 10;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    overflow: hidden;

    @media only screen and (min-width: ${breakpoints.tablet}) {
      ${!enabled &&
      css`
        display: block;
        left: 5vmin;
        bottom: 5vmin;
        width: 32vmin;
        height: 24vmin;
        max-width: 320px;
        max-height: 240px;
        z-index: 20;
        font-size: 0.6em;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
          0 2px 4px -1px rgba(0, 0, 0, 0.06);
      `}
    }

    @media only screen and (min-width: ${breakpoints.desktop}) {
      ${!enabled &&
      css`
        max-height: 300px;
        max-width: 400px;
      `}
    }
  `
)

const MapboxComponent = ({ enabled }) => {
  const { currentPosition, setCurrentPosition, setActiveMarker } =
    useContext(MapStateContext)
  const { trackEvent } = useMatomo()
  const mapRef = useRef()
  const [mapInstance, setMapInstance] = useState(null)
  const [positionMarker, setPositionMarker] = useState(null)

  // Create map
  useEffect(() => {
    if (!mapInstance && mapRef.current) {
      console.log("init mapbox")
      const map = new mapboxgl.Map({
        container: mapRef.current,
        style: "mapbox://styles/axe312/ckeskf0ic86j019s6u5dhy2d5",
        center: [13.4434186, 52.5034095],
        zoom: 12,
        accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
      })

      const mapillarySource = {
        type: "vector",
        tiles: [
          `https://tiles.mapillary.com/maps/vtp/mly1_public/2/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPILLARY_CLIENT_TOKEN}`,
        ],
        minzoom: 6,
        maxzoom: 14,
      }

      map.on("load", function () {
        // https://www.mapillary.com/developer/tiles-documentation/#sequence-layer
        map.addSource("mapillary", mapillarySource)

        // Add Mapillary sequence layer.
        map.addLayer(
          {
            id: "mapillary",
            type: "line",
            source: "mapillary",
            "source-layer": "sequence",
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-opacity": 0.3,
              "line-color": "#39AF64",
              "line-width": 2,
            },
          },
          "waterway-label"
        )
        map.addLayer(
          {
            id: "mapillary-images",
            type: "circle",
            source: "mapillary",
            "source-layer": "image",
            paint: {
              "circle-color": "#39AF64",
              "circle-radius": 6,
              "circle-opacity": 0.3,
            },
          },
          "waterway-label"
        )

        // Filter layers
        map.setFilter("mapillary", ["any", ["in", "id", ...sequences]])

        map.setFilter("mapillary-images", [
          "any",
          ["in", "sequence_id", ...sequences],
        ])

        map.on("click", "mapillary-images", function (e) {
          const cords = e.features[0].geometry.coordinates
          console.log(
            "Current click position:",
            JSON.stringify(
              {
                location: cords,
                imageKey: e.features[0].properties.id,
              },
              null,
              2
            )
          )
          setCurrentPosition({
            imageKey: e.features[0].properties.id,
            location: [e.lngLat.lng, e.lngLat.lat],
            shouldRotate: true,
          })
        })

        // Markers
        markers.forEach(marker => {
          var markerElement = document.createElement("div")
          markerElement.innerHTML = marker.icon
            ? icons[marker.icon]
            : icons.info
          markerElement.className = "marker"
          markerElement.style.width = "24px"
          markerElement.style.height = "24px"
          markerElement.style.color =
            typeof marker.imageKey === "number" ? "black" : "red"
          markerElement.style.cursor = "pointer"
          markerElement.style.zIndex = "10"

          markerElement.addEventListener("click", function () {
            setCurrentPosition({
              imageKey: marker.imageKey,
              location: marker.location,
              shouldRotate: true,
            })
            setActiveMarker(marker)
            trackEvent({
              category: "markers",
              action: "click",
              name: marker.title,
            })
          })
          new mapboxgl.Marker(markerElement)
            .setLngLat(marker.location)
            .addTo(map)
        })
      })

      map.addControl(new mapboxgl.NavigationControl())

      setMapInstance(map)

      return () => {
        console.log("unmount mapbox")
      }
    }
  }, [mapInstance, mapRef, setActiveMarker, setCurrentPosition, trackEvent])

  // Current position marker
  useEffect(() => {
    if (mapInstance) {
      if (positionMarker) {
        positionMarker.remove()
      }
      const markerElement = document.createElement("div")
      markerElement.innerHTML = icons.location
      markerElement.className = "marker"
      markerElement.style.width = "36px"
      markerElement.style.height = "36px"
      markerElement.style.color = "tomato"
      markerElement.style.pointerEvents = "none"
      markerElement.style.top = "-16px"
      markerElement.style.left = "0"
      markerElement.style.zIndex = "5"

      const marker = new mapboxgl.Marker(markerElement)
        .setLngLat(currentPosition.location)
        .addTo(mapInstance)

      setPositionMarker(marker)
    }
    // @todo check how we can avoid a loop cus of missing position marker
    // eslint-disable-next-line
  }, [mapInstance, currentPosition])

  // Follow active image on map
  useEffect(() => {
    if (mapInstance) {
      mapInstance.resize()
      mapInstance.flyTo({
        center: currentPosition.location,
        zoom: 18,
      })
    }
  }, [mapInstance, currentPosition, enabled])

  return <MapContainer ref={mapRef} enabled={enabled} />
}

MapboxComponent.propTypes = {
  enabled: propTypes.bool,
  currentPosition: propTypes.object,
  setCurrentPosition: propTypes.func,
  setActiveMarker: propTypes.func,
  openSidebar: propTypes.func,
}

export default MapboxComponent
