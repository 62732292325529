import styled from "@emotion/styled"

type ImageProps = {
  width?: string
}

const Image = styled.img<ImageProps>`
  width: ${props => props.width || "100%"};
  margin: auto;
  max-width: 100%;
`

export default Image
