import styled from "@emotion/styled"
import { getSize, getColor } from "./"

type TextProps = {
  size?: string
  weight?: number
  color?: string
  margin?: string
}

const Text = styled.div<TextProps>`
  margin-bottom: ${props => props.margin || getSize("xs")};
  line-height: 1.5em;
  font-size: ${props => getSize(props.size)};
  font-weight: ${props => props.weight};
  color: ${props => getColor(props.color)};
`

export default Text
