import styled from '@emotion/styled'

type AlignProps = {
  align?: string
}

const Align = styled.div<AlignProps>`
  text-align: ${(props) => props.align || 'center'};
`

export default Align
