import Align from "./Align"
import Button from "./Button"
import Container from "./Container"
import Gap from "./Gap"
import Headline from "./Headline"
import Image from "./Image"
import Map from "./Map"
import Modal from "./Modal"
import Padding from "./Padding"
import Switch from "./Switch"
import Text from "./Text"
import Title from "./Title"

import { colors, sizes } from "../config"

const getColor = (color?: string) => {
  if (color && colors[color]) {
    return colors[color]
  }

  return color
}

const getSize = (size?: string) => {
  if (size === "xxl") {
    return sizes.xxl
  }

  if (size === "xl") {
    return sizes.xl
  }

  if (size === "l") {
    return sizes.l
  }

  if (size === "m") {
    return sizes.m
  }

  if (size === "s") {
    return sizes.s
  }

  if (size === "xs") {
    return sizes.xs
  }

  if (size === "xxs") {
    return sizes.xxs
  }

  if (size) {
    return size
  }

  return "inherit"
}

export {
  getColor,
  getSize,
  Align,
  Button,
  Container,
  Gap,
  Headline,
  Image,
  Map,
  Modal,
  Padding,
  Switch,
  Text,
  Title,
}
