import React from "react"
import { Gap, Text } from "../"
import SwitchWrapper from "./Switch.style"

import toggleLeft from "./toggle-left.svg"
import toggleRight from "./toggle-right.svg"

type SwitchProps = {
  onChange: () => void
  isOn: boolean
  labelOn?: string
  labelOff?: string
}

const Switch: React.FC<SwitchProps> = ({
  onChange,
  isOn,
  labelOn,
  labelOff,
}) => {
  return (
    <SwitchWrapper onClick={onChange}>
      <Text color={isOn ? "default" : "primary"} margin="0">
        {labelOn}
      </Text>
      <Gap size="s" />
      <img src={isOn ? toggleRight : toggleLeft} alt="switch" />{" "}
      <Gap size="s" />
      <Text color={isOn ? "primary" : "default"} margin="0">
        {labelOff}
      </Text>
    </SwitchWrapper>
  )
}

export default Switch
