/* eslint-disable react/display-name */
import React from "react"
import ReactPlayer from "react-player"
import { Gap, Headline, Image, Text } from "../techboi-web-suite"

import infomobil from "./media/infomobil.jpg"
import marker2 from "./media/marker-2.jpg"
import marker3 from "./media/marker-3.jpg"
import marker4 from "./media/marker-4.jpg"
import marker5 from "./media/marker-5.jpg"
import marker6 from "./media/marker-6.jpg"
import marker7 from "./media/marker-7.jpg"
import marker8 from "./media/marker-8.jpg"
import marker9a from "./media/marker-9-1.jpg"
import marker9b from "./media/marker-9-2.jpg"
import marker9c from "./media/marker-9-3.jpg"
import marker10 from "./media/marker-10.jpg"
import marker11 from "./media/marker-11.jpg"
// import marker12 from "./media/marker-12.jpg"

/*
import marker13 from "./media/marker-13.jpg"
import marker14 from "./media/marker-14.jpg"
import marker15 from "./media/marker-15.jpg"
import marker16 from "./media/marker-16.jpg"
import marker17 from "./media/marker-17.jpg"
import marker18 from "./media/marker-18.jpg"
*/
import marker19 from "./media/marker-19.jpg"
import marker20 from "./media/marker-20.jpg"
import marker21 from "./media/marker-21.jpg"
import marker22 from "./media/marker-22.jpg"
import marker23 from "./media/marker-23.jpg"

import markerEsg23 from "./media/esg-23.jpg"
import marker47 from "./media/esg-47.jpg"
import marker48 from "./media/esg-48.jpg"
import marker49 from "./media/esg-49.jpg"
import marker50 from "./media/esg-50.jpg"
import marker51 from "./media/esg-51.jpg"
import marker53 from "./media/esg-53.jpg"
import marker54 from "./media/esg-54.jpg"
import marker55 from "./media/esg-55.jpg"
import marker56a from "./media/esg-56a.jpg"
import marker57 from "./media/esg-57.jpg"
import marker58 from "./media/esg-58.jpg"
import marker59 from "./media/esg-59.jpg"
import marker61 from "./media/esg-61.jpg"
import marker62 from "./media/esg-62.jpg"

// import marker67 from "./media/esg-67.jpg"
import marker68 from "./media/esg-68.jpg"
// import marker71 from "./media/esg-71.jpg"
import marker73 from "./media/esg-73.jpg"
import marker90 from "./media/esg-90.jpg"
import marker91 from "./media/esg-91.jpg"

import bild17 from "./media/bild-17.jpg"
import bild1990 from "./media/bild-1990.jpg"

const textBaustelle =
  "Dieses Gemälde befindet sich im Baustellengang des Bauprojekts „Pier 61|63“ und ist daher teilweise verdeckt. Im „Pier 61|63“ wird es ein Hotel, Mietwohnungen sowie Gastronomie- und Ladengeschäfte geben. Die Fertigstellung ist voraussichtlich Ende 2021."
const textPodcast =
  "Podcast produziert von den Künstlerinnen und Künstlern anlässlich des 30. Jahrestages der East Side Gallery, 2020"

const YouTubePlayer: React.FC<{ url: string }> = ({ url }) => {
  return <ReactPlayer controls={true} width="100%" url={url} />
}

/*
const SoundCloudPlayer: React.FC<{ url: string }> = ({ url }) => {
  return <ReactPlayer width="100%" url={url} />
}
*/

export const sequences = [
  "f8qpv73x02l2kjzrz91kht",
  "ewrbck1w24dj6koa2sztjz",
  "vf69kcs6swteogajt27653",
  "zqdkk4iajygaxfokuzgv8o",
]

export const markers = [
  {
    location: [13.444454669952393, 52.50319052918914],
    imageKey: 163790822344080,
    icon: "info",
    title: "Die East Side Gallery",
    content: () => (
      <>
        <Headline>Die East Side Gallery</Headline>
        <Image src={infomobil} alt="Infomobil" />
        <Gap size="m" />
        <Text>
          Nur wenige Wochen nach dem Mauerfall am 9. November 1989 hatten David
          Monty und Heike Stephan die Idee für die „East Side Gallery GDR“. Ab
          Februar 1990 nutzen 118 Künstlerinnen und Künstler ihre Chance,
          erstmals auf die Ostseite der Berliner Mauer zu malen. Ihre Gemälde
          stehen für die Freude über die friedliche Überwindung der Teilung
          Deutschlands, sind aber auch Mahnungen für soziale Gerechtigkeit,
          Menschenrechte und Umweltschutz. Seit November 1991 steht die East
          Side Gallery unter Denkmalschutz und ist seit 2018 Teil der Stiftung
          Berliner Mauer.
        </Text>
        <Gap size="m" />
        <Headline>So funktioniert der interaktive Rundgang </Headline>
        <Text>
          Sie haben die Möglichkeit, zwischen der Kartenansicht und der
          360-Grad-Ansicht des Geländes zu wechseln. Möchten Sie einen
          bestimmten Standort entlang der East Side Gallery ansteuern, klicken
          Sie in der Kartenansicht auf die gewünschte Stelle. Auf dem Stadtplan
          zeigen Ihnen Markierungspunkte zusätzliche Informationen an. Klicken
          Sie auf die Symbole, um Erklärungen, Bilder, Videos und Audios
          aufzurufen.
        </Text>
      </>
    ),
  },
  // Weisse seite
  {
    location: [13.442866802215576, 52.50375870751685],
    imageKey: 2948007525484228,
    icon: "info",
    title: "Die Berliner Mauer an der Mühlenstraße",
    content: () => (
      <>
        <Headline>Die Berliner Mauer an der Mühlenstraße</Headline>
        <Text>
          Die 1,3 Kilometer lange East Side Gallery ist das längste am
          Originalstandort erhaltene Teilstück der Berliner Mauer. Sie diente
          früher als so genannte Hinterlandmauer, die das Grenzgebiet nach
          Ost-Berlin hin abschloss. Die Spree gehörte in ihrer ganzen Breite zu
          Ost-Berlin, während das Kreuzberger Ufer bereits West-Berliner Gebiet
          war. Die in den Grenzstreifen weisende Seite der Mauer war weiß
          angestrichen. So sollten Flüchtende besser sichtbar sein.
        </Text>
        <Gap size="m" />
        <Text>
          Seit November 2019 ist dieser Abschnitt der spreegewandten Seite der
          Berliner Mauer wieder weiß angestrichen. Damit erinnert die Stiftung
          Berliner Mauer an ihre Funktion als unüberwindbares Sperrelement für
          mehr als 28 Jahre der Teilung Berlins.
        </Text>
        <Gap size="m" />
        <Image src={marker2} alt="Die weiße Seite der Berliner Mauer" />
        <Gap size="m" />
        <Text>
          Grenzstreifen Mühlenstraße Richtung Schillingbrücke Berlin 1988,
          Stiftung Berliner Mauer, Mauer-Archiv Hagen Koch
        </Text>
      </>
    ),
  },
  {
    location: [13.446123003959656, 52.50276602334665],
    imageKey: 295976475403673,
    icon: "info",
    title: "Hans Bierbrauer - Brandenburger Tor",
    content: () => (
      <>
        <Headline>Hans Bierbrauer - Brandenburger Tor</Headline>
        <Text>
          Das Gemälde „Brandenburger Tor“ des Künstlers Hans Bierbrauer wurde
          2009 restauriert. Das Gemälde befindet sich in Privateigentum und
          wurde nicht an die Stiftung Berliner Mauer übertragen. Es ist von
          Graffiti bedeckt.
        </Text>
        <Gap size="m" />
        <Image src={marker3} alt="Bild 1 Gemälde v. Hans Bierbrauer" />
        <Gap size="m" />
        <Text>
          Hans Bierbrauer, Brandenburger Tor, 2009, Foto: Künstlerinitiative
          East Side Gallery e.V.
        </Text>
      </>
    ),
  },
  {
    location: [13.445962071418762, 52.502805208673095],
    imageKey: 461308484955764,
    icon: "info",
    title: "Narenda Kumar Jain - Die Sieben Stufen der Erleuchtung",
    content: () => (
      <>
        <Headline>
          Narenda Kumar Jain - Die Sieben Stufen der Erleuchtung
        </Headline>
        <Text>
          Das Gemälde „Die Sieben Stufen der Erleuchtung“ des Künstlers Narenda
          Kumar Jain wurde 2009 restauriert. Das Gemälde befindet sich in
          Privateigentum und wurde nicht an die Stiftung Berliner Mauer
          übertragen. Es ist von Graffiti bedeckt.
        </Text>
        <Gap size="m" />
        <Image src={marker4} alt="Bild 2 Gemälde v. Narenda Kumar Jain" />
        <Gap size="m" />
        <Text>
          Narenda Kumar Jain, Die Sieben Stufen der Erleuchtung, 2009, Foto:
          Künstlerinitiative East Side Gallery e.V.
        </Text>
      </>
    ),
  },
  {
    location: [13.44578504562378, 52.50286072115907],
    imageKey: 1284002385330769,
    icon: "info",
    title: "Fulvio Pinna - Hymne an die Freude",
    content: () => (
      <>
        <Headline>Fulvio Pinna - Hymne an die Freude</Headline>
        <Text>
          Das Gemälde „Hymne an die Freude“ des Künstlers Fulvio Pinna wurde
          2009 restauriert. Das Gemälde befindet sich in Privateigentum und
          wurde nicht an die Stiftung Berliner Mauer übertragen. Es ist von
          Graffiti bedeckt.
        </Text>
        <Gap size="m" />
        <Image src={marker5} alt="Bild 3 Gemälde v. Fulvio Pinna" />
        <Gap size="m" />
        <Text>
          Fulvio Pinna, Hymne an die Freude, 2009, Foto: Künstlerinitiative East
          Side Gallery e.V.
        </Text>
      </>
    ),
  },
  {
    location: [13.445071578025818, 52.50285745572066],
    imageKey: 315159246895019,
    icon: "audio",
    title: "Kikue Miyatake - Paradise Out Of The Darkness",
    content: () => (
      <>
        <Headline>Kikue Miyatake - Paradise Out Of The Darkness</Headline>
        <Text>
          Das Gemälde von Kikue Miyatake „Paradise Out Of The Darkness“ wurde
          1997 beim Umbau des Mühlenspeichers zu einem Gastronomiebetrieb
          versetzt und befindet sich in Privateigentum. Es ist heute von
          Graffiti bedeckt.
        </Text>
        <Gap size="m" />
        <Image src={marker6} alt="Bild 4 Gemälde v. Kikue Miyatake" />
        <Gap size="m" />
        <Text>
          Foto: Kikue Miyatake, Paradise Out Of The Darkness, 2009, Foto:
          Künstlerinitiative East Side Gallery e.V.
        </Text>
        <Gap size="m" />
        <YouTubePlayer url="https://youtu.be/eJf_Gai6Eiw" />
        <Gap size="m" />
        <Text>{textPodcast}</Text>
      </>
    ),
  },
  {
    location: [13.445473909378052, 52.50295215333611],
    imageKey: 4182909355100217,
    icon: "info",
    title: "Günther Schäfer - Vaterland",
    content: () => (
      <>
        <Headline>Günther Schäfer - Vaterland</Headline>
        <Text>
          Das Gemälde „Vaterland“ des Künstlers Günther Schäfer wurde 2009
          restauriert. Das Gemälde befindet sich in Privateigentum und wurde
          nicht an die Stiftung Berliner Mauer übertragen. Es ist von Graffiti
          bedeckt.
        </Text>
        <Gap size="m" />
        <Image src={marker7} alt="Bild 5 Gemälde v. Günther Schäfer" />
        <Gap size="m" />
        <Text>
          Günther Schäfer, Vaterland, 2009, Foto: Künstlerinitiative East Side
          Gallery e.V.
        </Text>
      </>
    ),
  },
  {
    location: [13.445366621017456, 52.50298480763894],
    imageKey: 918135335637579,
    icon: "info",
    title: "Georg Lutz - ohne Titel",
    content: () => (
      <>
        <Headline>Georg Lutz - ohne Titel</Headline>
        <Text>
          Das Gemälde des Künstlers Georg Lutz wurde 2009 restauriert. Das
          Gemälde befindet sich in Privateigentum und wurde nicht an die
          Stiftung Berliner Mauer übertragen. Es ist von Graffiti bedeckt.
        </Text>
        <Gap size="m" />
        <Image src={marker8} alt="Bild 6 Gemälde v. Georg Lutz" />
        <Gap size="m" />
        <Text>
          Georg Lutz, ohne Titel, 2009, Foto: Künstlerinitiative East Side
          Gallery e.V.
        </Text>
      </>
    ),
  },
  {
    location: [13.443467617034912, 52.50364768474276],
    imageKey: 302142011607778,
    icon: "info",
    title: "Die unbemalten Flächen an der East Side Gallery",
    content: () => (
      <>
        <Headline>Die unbemalten Flächen an der East Side Gallery</Headline>
        <Text>
          Aufgrund des witterungsbedingten Verfalls sowie zahlreicher
          Beschmierungen wurde die East Side Gallery 2000 und 2009 großflächig
          saniert. Bei der Sanierung 2009 wurde das Mauerwerk instandgesetzt.
          Dafür wurde ein Großteil der Bilder abgestrahlt. Die KünstlerInnen
          waren eingeladen, ihre Gemälde noch einmal auf die Berliner Mauer zu
          malen. Während die Mehrheit der KünstlerInnen dem nachkam, kritisierte
          eine kleinere Gruppe das Vorgehen und weigerte sich, ihre Gemälde neu
          aufzubringen. Diese Mauersegmente sind seitdem unbemalt.
        </Text>
        <Gap size="m" />
        <Text>
          Seit 2009 nicht mehr an der East Side Gallery zu sehen u.a.:
        </Text>
        <Gap size="m" />
        <Image src={marker9a} alt="Weiße Fläche Bild 17-19" />
        <Gap size="m" />
        <Text>
          Siegfried Santoni, Maschine-Mensch, Postkarte 1990, Stiftung Berliner
          Mauer
        </Text>
        <Gap size="m" />
        <Image src={marker9b} alt="Weiße Fläche Bild 17-19" />
        <Gap size="m" />
        <Text>
          Bodo Sperling, Die Transformation des Pentagramms zu einem
          Friedensstern in einem großen Europa ohne Mauern, Postkarte 1990,
          Stiftung Berliner Mauer
        </Text>
        <Gap size="m" />
        <Image src={marker9c} alt="Weiße Fläche Bild 17-19" />
        <Gap size="m" />
        <Text>
          Barbara Greul Aschanta, Deutschland im November, Postkarte 1990,
          Stiftung Berliner Mauer
        </Text>
      </>
    ),
  },
  {
    location: [13.437159061431885, 52.506400313754085],
    imageKey: 2600374940266148,
    icon: "info",
    title: "Bauvorhaben an der East Side Gallery",
    content: () => (
      <>
        <Headline>Bauvorhaben an der East Side Gallery</Headline>
        <Text>
          Nach dem Mauerfall am 9. November 1989 lag der ehemalige Grenzstreifen
          an der Mühlenstraße brach. Bars, Clubs und eine Wagenburg eroberten
          das Areal bis in die 1990-er Jahre hinein. Zu dieser Zeit war der
          attraktive Spreeraum bereits Teil von stadtplanerischen Konzepten. Die
          Bauprojekte verdrängten die temporären Nutzungen. Für die Bebauungen
          wurden einzelne Mauersegmente versetzt, was breiten Protest bei den
          BerlinerInnen auslöste. 2018 hat das Land Berlin der Stiftung Berliner
          Mauer den Auftrag erteilt, das Denkmal zu erhalten und zu pflegen.
          Weitere Bebauungen im ehemaligen Grenzstreifen wird es nicht geben.
          Die Baustelle für den „Pier 61I63“ wird voraussichtlich Ende 2021
          beendet sein.{" "}
        </Text>
        <Gap size="m" />
        <Image
          src={marker10}
          alt="Bild 67 Gemälde v. Karina Bjerregaard / Lotte Haubert-Nielsen"
        />
        <Text>
          Das Gemälde von Karina Bjerregaard und Lotte Haubert-Nielsen „Himlen
          over Berlin“ wurde für eine Zufahrt zum Wohnturm „Living Levels“
          versetzt. Foto: Künstlerinitiative East Side Gallery e.V., 2009
        </Text>
      </>
    ),
  },
  {
    location: [13.436960577964783, 52.506468882383075],
    imageKey: 973130056839642,
    icon: "audio",
    title: "Karina Bjerregaard – Himlen over Berlin",
    content: () => (
      <>
        <Headline>Karina Bjerregaard – Himlen over Berlin</Headline>
        <Image src={marker10} alt="Karina Bjerregaard – Himlen over Berlin" />
        <Gap size="m" />
        <Text>
          Karina Bjerregaard und Lotte Haubart, Himlen over Berlin, 2009, Foto:
          Künstlerinitiative East Side Gallery e.V.
        </Text>
        <Gap size="m" />
        <YouTubePlayer url="https://youtu.be/dOuEE0Zpx4M" />
        <Gap size="m" />
        <Text>{textPodcast}</Text>
      </>
    ),
  },
  {
    location: [13.43426764011383, 52.50791206394578],
    imageKey: 2145783768894128,
    icon: "info",
    title: "Mirta Domacinovic - Zeichen in der Reihe",
    content: () => (
      <>
        <Headline>Mirta Domacinovic - Zeichen in der Reihe</Headline>
        <Text>
          Das Gemälde von Mirta Domacinovic „Zeichen in der Reihe“ wurde für die
          Zufahrten zum Wohnturm „Living Levels“ und zum „Pier 61I63“ versetzt.
          Es steht teilweise am nördlichen Ende des Parks, weitere Segmente
          stehen auf der Baustelle des „Pier 61I63“. Heute sind sie von Graffiti
          bedeckt.
        </Text>
        <Gap size="m" />
        <Image src={marker11} alt="Bild 60 Gemälde v. Mirta Domacinovic" />
        <Text>
          Mirta Domacinovic, Zeichen in Reihe, 2009, Foto: Künstlerinitiative
          East Side Gallery e.V.
        </Text>
      </>
    ),
  },
  {
    location: [13.441053628921509, 52.504411776982664],
    imageKey: 1663914990458918,
    icon: "info",
    title: "Wjatscheslaw Schljachow – Masken",
    content: () => (
      <>
        <Headline>Wjatscheslaw Schljachow – Masken</Headline>
        <Text>
          Das Gemälde von Wjatscheslaw Schljachow „Masken“ wurde 2006 für den
          Zugang von der Arena zur Spree versetzt.{" "}
        </Text>
        <Gap size="m" />
        <YouTubePlayer url="https://youtu.be/h471zig-DcY" />
      </>
    ),
  },
  {
    location: [13.443601727485657, 52.50349094623138],
    imageKey: 316390333465091,
    icon: "video",
    title: "Kurz erklärt: Der Ort im Überblick",
    content: () => (
      <>
        <Headline>Kurz erklärt: Der Ort im Überblick</Headline>
        <YouTubePlayer url="https://youtu.be/BX1Nt0VMjjo" />
      </>
    ),
  },
  {
    location: [13.444320559501648, 52.503210121667536],
    imageKey: 175771201010134,
    icon: "video",
    title: "Kurz erklärt: Architektonische Spuren",
    content: () => (
      <>
        <Headline>Kurz erklärt: Architektonische Spuren</Headline>
        <YouTubePlayer url="https://youtu.be/lnCOuh3o_gM" />
      </>
    ),
  },
  {
    location: [13.443092107772827, 52.503481150055876],
    imageKey: 313146637088469,
    icon: "video",
    title: "Kurz erklärt: Gedenken an die Todesopfer im Bereich Mühlenstraße",
    content: () => (
      <>
        <Headline>
          Kurz erklärt: Gedenken an die Todesopfer im Bereich Mühlenstraße
        </Headline>
        <YouTubePlayer url="https://youtu.be/UKPgCnnzAh8" />
      </>
    ),
  },
  {
    location: [13.440957069396973, 52.504447695521804],
    imageKey: 307141461003307,
    icon: "video",
    title:
      "Kurz erklärt: Der Mauerfall und die Entstehung der East Side Gallery",
    content: () => (
      <>
        <Headline>
          Kurz erklärt: Der Mauerfall und die Entstehung der East Side Gallery
        </Headline>
        <YouTubePlayer url="https://youtu.be/h471zig-DcY" />
      </>
    ),
  },
  {
    location: [13.436080813407898, 52.50739291585427],
    imageKey: 1731770063696723,
    icon: "video",
    title: "Kurz erklärt: Sanierung und Kontroversen an der East Side Gallery",
    content: () => (
      <>
        <Headline>
          Kurz erklärt: Sanierung und Kontroversen an der East Side Gallery
        </Headline>
        <YouTubePlayer url="https://youtu.be/uszHX8APMow" />
      </>
    ),
  },
  {
    location: [13.434337377548218, 52.5082320389603],
    imageKey: 477165356943005,
    icon: "video",
    title:
      "Kurz erklärt: Das DDR-Grenzsystem und der Grenzverlauf an der Mühlenstraße",
    content: () => (
      <>
        <Headline>
          Kurz erklärt: Das DDR-Grenzsystem und der Grenzverlauf an der
          Mühlenstraße
        </Headline>
        <YouTubePlayer url="https://youtu.be/a9mkqq8vO-M" />
      </>
    ),
  },
  {
    location: [13.444722890853882, 52.503193794602794],
    imageKey: 806743546626502,
    icon: "audio",
    title: "Gábor Simon - Space Magik",
    content: () => (
      <>
        <Headline>Gábor Simon - Space Magik</Headline>
        <Image src={marker19} alt="Gábor Simon - Space Magik" />
        <Gap size="m" />
        <Text>
          Gábor Simon, Space Magik, 2009, Foto: Künstlerinitiative East Side
          Gallery e.V.
        </Text>
        <Gap size="m" />
        <YouTubePlayer url="https://www.youtube.com/watch?v=uQ_EqyiQG6I" />
        <Gap size="m" />
        <Text>{textPodcast}</Text>
      </>
    ),
  },
  {
    location: [13.441380858421326, 52.50439871568844],
    imageKey: 1086474328546033,
    icon: "audio",
    title: "Jeanett Kipka – Vogelflug",
    content: () => (
      <>
        <Headline>Jeanett Kipka – Vogelflug</Headline>
        <Image src={marker20} alt="Jeanett Kipka – Vogelflug" />
        <Gap size="m" />
        <Text>
          Jeanett Kipka, Vogelflug, 2009, Foto: Künstlerinitiative East Side
          Gallery e.V.
        </Text>
        <Gap size="m" />
        <YouTubePlayer url="https://www.youtube.com/watch?v=ee8lNNKSJxY" />
        <Gap size="m" />
        <Text>{textPodcast}</Text>
      </>
    ),
  },
  {
    location: [13.435158133506775, 52.508065521641385],
    imageKey: 946576642762379,
    icon: "audio",
    title: "Kiddy Citny - qui baise qui",
    content: () => (
      <>
        <Headline>Kiddy Citny - qui baise qui</Headline>
        <Image src={marker21} alt="Kiddy Citny - qui baise qui" />
        <Gap size="m" />
        <Text>
          Kiddy Citny, qui baise qui, 2009, Foto: Künstlerinitiative East Side
          Gallery e.V.
        </Text>
        <Gap size="m" />
        <YouTubePlayer url="https://www.youtube.com/watch?v=TdHjs6W9wWg" />
        <Gap size="m" />
        <Text>
          Podcast produziert von der Stiftung Berliner Mauer anlässlich des 30.
          Jahrestages der East Side Gallery, 2020
        </Text>
      </>
    ),
  },
  {
    location: [13.437303900718689, 52.50640684410433],
    imageKey: 485434136075243,
    icon: "audio",
    title: "Teresa Casanueva - Sprossen",
    content: () => (
      <>
        <Headline>Teresa Casanueva - Sprossen</Headline>
        <Image src={marker22} alt="Teresa Casanueva – Sprossen" />
        <Gap size="m" />
        <Text>
          Teresa Casanueva, Sprossen, 2009, Foto: Künstlerinitiative East Side
          Gallery e.V.
        </Text>
        <Gap size="m" />
        <YouTubePlayer url="https://www.youtube.com/watch?v=ghZdix3NXVA" />
        <Gap size="m" />
        <Text>
          Podcast produziert von der Stiftung Berliner Mauer anlässlich des 30.
          Jahrestages der East Side Gallery, 2020
        </Text>
      </>
    ),
  },
  {
    location: [13.436933755874634, 52.50671703462251],
    imageKey: 822322051996599,
    icon: "audio",
    title: "Margaret Hunter und Peter Russell - Hands",
    content: () => (
      <>
        <Headline>Margaret Hunter und Peter Russell - Hands</Headline>
        <Text>
          Das Gemälde „Hands“ von Margaret Hunter und Peter Russell ist als
          einziges Original von 1990 erhalten. Anlässlich des 30. Jahrestags der
          East Side Gallery hat die Stiftung Berliner Mauer es im September 2020
          konservieren lassen.
        </Text>
        <Gap size="m" />
        <Image
          style={{ width: "60%" }}
          src={marker23}
          alt="Bild 71 v. Margaret Hunter und Peter Russell"
        />
        <Gap size="m" />
        <Text>Foto: Stiftung Berliner Mauer, 2009</Text>
        <Gap size="m" />
        <YouTubePlayer url="https://youtu.be/kyE9u69cgVg" />
        <Gap size="m" />
        <Text>{textPodcast}</Text>
      </>
    ),
  },
  {
    location: [13.439353108406067, 52.50517585594099],
    imageKey: 294162015590072,
    icon: "info",
    title: "Andy Weiss – Geist-Reise",
    content: () => (
      <>
        <Headline>Andy Weiss – Geist-Reise</Headline>
        <Text>{textBaustelle}</Text>
        <Image src={marker47} alt="Andy Weiss, Geist-Reise, 2009" />
        <Gap size="m" />
        <Text>
          Andy Weiss, Geist-Reise, 2009, Foto: Künstlerinitiative East Side
          Gallery e.V.
        </Text>
      </>
    ),
  },
  {
    location: [13.43927264213562, 52.505208508592006],
    imageKey: 2912733435611353,
    icon: "audio",
    title: "Gabriel Heimler – Der Mauerspringer",
    content: () => (
      <>
        <Headline>Gabriel Heimler – Der Mauerspringer</Headline>
        <Text>{textBaustelle}</Text>
        <Gap size="m" />
        <Image src={marker48} alt="Gabriel Heimler – Der Mauerspringer" />
        <Gap size="m" />
        <Text>
          Gabriel Heimler, Der Mauerspringer, 2009, Foto: Künstlerinitiative
          East Side Gallery e.V.
        </Text>
        <Gap size="m" />
        <YouTubePlayer url="https://youtu.be/G6ws-KZtyL8" />
        <Gap size="m" />
        <Text>{textPodcast}</Text>
      </>
    ),
  },
  {
    location: [13.439186811447144, 52.50524442648015],
    imageKey: 522108842498579,
    icon: "info",
    title: "Lislot Blunier – ohne Titel",
    content: () => (
      <>
        <Headline>Lislot Blunier – ohne Titel</Headline>
        <Text>{textBaustelle}</Text>
        <Gap size="m" />
        <Image src={marker49} alt="Lislot Blunier – ohne Titel" />
        <Gap size="m" />
        <Text>
          Lislot Blunier, ohne Titel, 2009, Foto: Künstlerinitiative East Side
          Gallery e.V.
        </Text>
      </>
    ),
  },
  {
    location: [13.43901515007019, 52.50532605793484],
    imageKey: 476584630327604,
    icon: "info",
    title: "Salvatore de Fazio – Dawn Of Peace",
    content: () => (
      <>
        <Headline>Salvatore de Fazio – Dawn Of Peace</Headline>
        <Text>{textBaustelle}</Text>
        <Gap size="m" />
        <Image src={marker50} alt="Salvatore de Fazio – Dawn Of Peace" />
        <Gap size="m" />
        <Text>
          Salvatore de Fazio, Dawn Of Peace, 2009, Foto: Künstlerinitiative East
          Side Gallery e.V.
        </Text>
      </>
    ),
  },
  {
    location: [13.438725471496582, 52.505469728926926],
    imageKey: 844942172760064,
    icon: "info",
    title: "Gerald Kriedner – Götterdämmerung",
    content: () => (
      <>
        <Headline>Gerald Kriedner – Götterdämmerung</Headline>
        <Text>{textBaustelle}</Text>
        <Gap size="m" />
        <Image src={marker51} alt="Gerald Kriedner – Götterdämmerung" />
        <Gap size="m" />
        <Text>
          Gerald Kriedner, Götterdämmerung, 2009, Foto: Künstlerinitiative East
          Side Gallery e.V.
        </Text>
      </>
    ),
  },
  {
    location: [13.438457250595093, 52.505606868981346],
    imageKey: 1386429245074796,
    icon: "info",
    title: "Yvonne Matzat (Yoni) – Berlin bei Nacht",
    content: () => (
      <>
        <Headline>Yvonne Matzat (Yoni) – Berlin bei Nacht</Headline>
        <Text>{textBaustelle}</Text>
        <Gap size="m" />
        <Image src={marker53} alt="Yvonne Matzat (Yoni) – Berlin bei Nacht" />
        <Gap size="m" />
        <Text>
          Yvonne Matzat, Berlin bei Nacht, 2009, Foto: Künstlerinitiative East
          Side Gallery e.V.
        </Text>
      </>
    ),
  },
  {
    location: [13.438387513160706, 52.50565584746849],
    imageKey: 814272739189999,
    icon: "info",
    title: "Peter Peinzger – Stadtmenschen",
    content: () => (
      <>
        <Headline>Peter Peinzger – Stadtmenschen</Headline>
        <Text>{textBaustelle}</Text>
        <Gap size="m" />
        <Image src={marker54} alt="Peter Peinzger – Stadtmenschen" />
        <Gap size="m" />
        <Text>
          Peter Peinzger, Stadtmenschen, 2009, Foto: Künstlerinitiative East
          Side Gallery e.V.
        </Text>
      </>
    ),
  },
  {
    location: [13.438172936439514, 52.50578972172164],
    imageKey: 470473630726818,
    icon: "info",
    title: "Elisa Budzinski – Wer will, dass die Welt",
    content: () => (
      <>
        <Headline>Elisa Budzinski – Wer will, dass die Welt...</Headline>
        <Text>{textBaustelle}</Text>
        <Gap size="m" />
        <Image
          src={marker55}
          alt="Elisa Budzinski – Wer will, dass die Welt..."
        />
        <Gap size="m" />
        <Text>
          Elisa Budzinski, Wer will, dass die Welt..., 2009, Foto:
          Künstlerinitiative East Side Gallery e.V.
        </Text>
      </>
    ),
  },
  {
    location: [13.438108563423157, 52.505835434787855],
    imageKey: 487047122499486,
    icon: "audio",
    title: "Sabine Kunz – Die Tanzenden",
    content: () => (
      <>
        <Headline>Sabine Kunz – Die Tanzenden</Headline>
        <Text>{textBaustelle}</Text>
        <Gap size="m" />
        <Image src={marker56a} alt="Sabine Kunz – Die Tanzenden" />
        <Gap size="m" />
        <Text>
          Sabine Kunz, Die Tanzenden, 2009, Foto: Künstlerinitiative East Side
          Gallery e.V.
        </Text>
        <Gap size="m" />
        <YouTubePlayer url="https://youtu.be/tapFp4QSMXo" />
        <Gap size="m" />
        <Text>{textPodcast}</Text>
      </>
    ),
  },
  {
    location: [13.437963724136353, 52.50594318682727],
    imageKey: 216225323367539,
    icon: "info",
    title: "Lance Keller – The Wall",
    content: () => (
      <>
        <Headline>Lance Keller – The Wall</Headline>
        <Text>{textBaustelle}</Text>
        <Gap size="m" />
        <Image src={marker57} alt="Lance Keller – The Wall" />
        <Gap size="m" />
        <Text>
          Lance Keller, The Wall, 2009, Foto: Künstlerinitiative East Side
          Gallery e.V.
        </Text>
      </>
    ),
  },
  {
    location: [13.437910079956055, 52.505979104115056],
    imageKey: 148465970586816,
    icon: "info",
    title: "Jacky Ramier (Jay One) – ohne Titel",
    content: () => (
      <>
        <Headline>Jacky Ramier (Jay One) – ohne Titel</Headline>
        <Text>{textBaustelle}</Text>
        <Gap size="m" />
        <Image src={marker58} alt="Jacky Ramier (Jay One) – ohne Titel" />
        <Gap size="m" />
        <Text>
          Jacky Ramier, ohne Titel, 2009, Foto: Künstlerinitiative East Side
          Gallery e.V.
        </Text>
      </>
    ),
  },
  {
    location: [13.437856435775757, 52.50602808218741],
    imageKey: 830249121032483,
    icon: "info",
    title: "Klaus Niethardt – Justitia",
    content: () => (
      <>
        <Headline>Klaus Niethardt – Justitia</Headline>
        <Gap size="m" />
        <Text>{textBaustelle}</Text>
        <Image src={marker59} alt="Klaus Niethardt – Justitia" />
        <Gap size="m" />
        <Text>
          Klaus Niethardt, Justitia, 2009, Foto: Künstlerinitiative East Side
          Gallery e.V.
        </Text>
      </>
    ),
  },
  {
    location: [13.43775987625122, 52.50610318179244],
    imageKey: 471772787215926,
    icon: "info",
    title: "Patrizio Porracchia – Der Blitz",
    content: () => (
      <>
        <Headline>Patrizio Porracchia – Der Blitz</Headline>
        <Text>{textBaustelle}</Text>
        <Gap size="m" />
        <Image src={marker61} alt="Patrizio Porracchia – Der Blitz" />
        <Gap size="m" />
        <Text>
          Patrizio Porracchia, Der Blitz, 2009, Foto: Künstlerinitiative East
          Side Gallery e.V.
        </Text>
      </>
    ),
  },
  {
    location: [13.437690138816833, 52.506145629338505],
    imageKey: 271970560971054,
    icon: "info",
    title: "Ines Bayer und Raik Hönemann – Es gilt viele Mauern abzubauen",
    content: () => (
      <>
        <Headline>
          Ines Bayer und Raik Hönemann – Es gilt viele Mauern abzubauen
        </Headline>
        <Text>{textBaustelle}</Text>
        <Gap size="m" />
        <Image
          src={marker62}
          alt="Ines Bayer und Raik Hönemann – Es gilt viele Mauern abzubauen "
        />
        <Gap size="m" />
        <Text>
          Ines Bayer, Raik Hönemann, Es gilt viele Mauern abzubauen, 2009, Foto:
          Künstlerinitiative East Side Gallery e.V.
        </Text>
      </>
    ),
  },
  {
    location: [13.436805009841919, 52.506824784500765],
    imageKey: 818447052211876,
    icon: "audio",
    title: "Margaret Hunter – Joint Venture",
    content: () => (
      <>
        <Headline>Margaret Hunter – Joint Venture</Headline>
        <Image src={marker73} alt="Margaret Hunter – Joint Venture" />
        <Gap size="m" />
        <Text>
          Margaret Hunter, Joint Venture, 2009, Foto: Künstlerinitiative East
          Side Gallery e.V.
        </Text>
        <Gap size="m" />
        <YouTubePlayer url="https://youtu.be/ELeEeWgOdZ0" />
        <Gap size="m" />
        <Text>{textPodcast}</Text>
      </>
    ),
  },
  {
    location: [13.437126874923706, 52.506560307055366],
    imageKey: 161939415871325,
    icon: "audio",
    title: "Christine Kühn – Touch The Wall",
    content: () => (
      <>
        <Headline>Christine Kühn – Touch The Wall</Headline>
        <Image src={marker68} alt="Christine Kühn – Touch The Wall" />
        <Gap size="m" />
        <Text>
          Christine Kühn, Touch The Wall, 2009, Foto: Künstlerinitiative East
          Side Gallery e.V.
        </Text>
        <Gap size="m" />
        <YouTubePlayer url="https://youtu.be/-iD30j-n1g4" />
        <Gap size="m" />
        <Text>{textPodcast}</Text>
      </>
    ),
  },
  {
    location: [13.435485363006592, 52.50784023249989],
    imageKey: 2884262158496980,
    icon: "audio",
    title: "Susanne Kunjappu – Curriculum Vitae",
    content: () => (
      <>
        <Headline>Susanne Kunjappu – Curriculum Vitae</Headline>
        <Image src={marker90} alt="Susanne Kunjappu – Curriculum Vitae" />
        <Gap size="m" />
        <Text>
          Susanne Kunjappu, Curriculum Vitae, 2009, Foto: Künstlerinitiative
          East Side Gallery e.V.
        </Text>
        <Gap size="m" />
        <YouTubePlayer url="https://youtu.be/KvJw3lF6MZc" />
        <Gap size="m" />
        <Text>{textPodcast}</Text>
      </>
    ),
  },
  {
    location: [13.435361981391907, 52.50792838925801],
    imageKey: 329536648562449,
    icon: "audio",
    title: "Mary Mackey – Tolerance",
    content: () => (
      <>
        <Headline>Mary Mackey – Tolerance</Headline>
        <Image src={marker91} alt="Mary Mackey – Tolerance" />
        <Gap size="m" />
        <Text>
          Mary Mackey, Tolerance, 2009, Foto: Künstlerinitiative East Side
          Gallery e.V.
        </Text>
        <Gap size="m" />
        <YouTubePlayer url="https://youtu.be/y7FxKuKZx3k" />
        <Gap size="m" />
        <Text>{textPodcast}</Text>
      </>
    ),
  },
  {
    location: [13.443065285682678, 52.50380115732696],
    imageKey: 471373527273060,
    icon: "audio",
    title: "Catrin Resch – Europas Frühling",
    content: () => (
      <>
        <Headline>Catrin Resch – Europas Frühling</Headline>
        <Image src={markerEsg23} alt="Catrin Resch – Europas Frühling" />
        <Gap size="m" />
        <Text>
          Catrin Resch, Europas Frühling, 2009, Künstlerinitiative East Side
          Gallery e.V.
        </Text>
        <Gap size="m" />
        <YouTubePlayer url="https://youtu.be/kM2R3lgQN1o" />
        <Gap size="m" />
        <Text>{textPodcast}</Text>
      </>
    ),
  },
  {
    location: [13.443692922592163, 52.503566050171145],
    imageKey: 279401807216294,
    icon: "audio",
    title: "Siegfried Santoni – Maschine-Mensch",
    content: () => (
      <>
        <Headline>Siegfried Santoni – Maschine-Mensch</Headline>
        <Image src={bild17} alt="Siegfried Santoni – Maschine-Mensch" />
        <Gap size="m" />
        <Text>
          Siegfried Santoni, Maschine-Mensch, Postkarte 1990, Stiftung Berliner
          Mauer
        </Text>
        <Gap size="m" />
        <YouTubePlayer url="https://youtu.be/IksUCt2KbsI" />
        <Gap size="m" />
        <Text>{textPodcast}</Text>
      </>
    ),
  },
  {
    location: [13.444561958312988, 52.50315787503911],
    imageKey: 504668674008865,
    icon: "audio",
    title: "Entstehung der East Side Gallery",
    content: () => (
      <>
        <Headline>Entstehung der East Side Gallery </Headline>
        <Text>
          Christine MacLean übernahm im Frühjahr 1990 die Organisation des
          Projekts „East Side Gallery“. In ihrem Podcast berichtet sie von der
          Entstehungsphase des Denkmals.
        </Text>
        <Gap size="m" />
        <Image src={bild1990} alt="Entstehung der East Side Gallery" />
        <Gap size="m" />
        <Text>Foto: Mary Mackey, 1990</Text>
        <Gap size="m" />
        <YouTubePlayer url="https://youtu.be/shj6Wl7Luuw" />
        <Gap size="m" />
        <Text>{textPodcast}</Text>
      </>
    ),
  },
]
