import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom"
import createPersistedState from "use-persisted-state"
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react"

import { colors, fonts, sizes } from "./config"
import {
  Align,
  Button,
  Gap,
  Headline,
  Image,
  Map,
  Modal,
  Text,
} from "./techboi-web-suite"
import Imprint from "./pages/Imprint"
import Logo from "./assets/logo-stiftung-berliner-mauer.svg"

const usePrivacyState = createPersistedState("privacy")

const AppWrapper = styled.div`
  background: ${colors.background};
  font-family: ${fonts.body};
  font-size: 18px;
  color: ${colors.default};
`

const Footer = styled.div`
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  padding: ${sizes.s};
  background: ${colors.background};
  font-size: 13px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);

  & a {
    display: inline-block;
    text-decoration: none;
  }
  & svg {
    vertical-align: bottom;
  }
`

function App() {
  const [checkboxIsChecked, setIsChecked] = usePrivacyState(false)
  const [modalIsOpen, setIsOpen] = useState(!checkboxIsChecked)
  const [matomoInstance, setMatomoInstance] = useState(null)

  function closeModal() {
    if (!checkboxIsChecked) {
      return
    }

    setIsOpen(false)
  }

  function toggleCheckbox() {
    setIsChecked(!checkboxIsChecked)
  }

  useEffect(() => {
    if (checkboxIsChecked && !matomoInstance) {
      const instance = createInstance({
        urlBase: "https://statistics.hashbite.net/",
        siteId: 10,
        configurations: {
          disableCookies: true,
          setSecureCookie: true,
          setRequestMethod: "POST",
        },
      })
      instance.trackPageView()
      setMatomoInstance(instance)
    }
  }, [checkboxIsChecked, matomoInstance])

  return (
    <AppWrapper>
      <Router>
        <Footer>
          <Link to="/">
            <svg
              style={{ width: "16px" }}
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
            </svg>
          </Link>{" "}
          &middot; <Link to="/impressum">Impressum</Link>
        </Footer>
        <Switch>
          <Route path="/impressum">
            <Imprint />
          </Route>
          <Route path="/">
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              hideClose={true}
            >
              <Align align="center">
                <Image width="400px" src={Logo} alt="Stiftung Berliner Mauer" />
              </Align>
              <Gap size="xl" />
              <Headline>
                Willkommen zum virtuellen Rundgang an der East Side Gallery
              </Headline>
              <Text>
                Erkunden Sie die historischen Spuren und Geschichten der
                weltweit größten Open-Air-Galerie auf dem längsten noch
                erhaltenen Teilstück der Berliner Mauer.
              </Text>
              <Gap size="l" />
              <Text>
                <label>
                  <input
                    type="checkbox"
                    checked={checkboxIsChecked}
                    onChange={toggleCheckbox}
                  />{" "}
                  Ich stimme der{" "}
                  <a href="https://www.stiftung-berliner-mauer.de/de/datenschutz-4.html">
                    Datenverarbeitung
                  </a>{" "}
                  zu
                </label>
              </Text>
              <Gap size="l" />
              <Button
                disabled={!checkboxIsChecked}
                background="primary"
                color="inverted"
                onClick={closeModal}
              >
                TOUR STARTEN
              </Button>
            </Modal>
            {matomoInstance && (
              <MatomoProvider value={matomoInstance}>
                <Map />
              </MatomoProvider>
            )}
          </Route>
        </Switch>
      </Router>
    </AppWrapper>
  )
}

export default App
