import React, { useState, useEffect, useRef, useContext, useMemo } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useWindowWidth } from "@react-hook/window-size/throttled"
import { useMatomo } from "@datapunt/matomo-tracker-react"

import { Button, Gap } from ".."
import { sizes, breakpoints } from "../../config"
import { markers } from "../../config/markers"
import { MapStateContext } from "../../contexts/map-state"

type SidebarWrapperProps = {
  isOpen: boolean
  hasActiveMarker: boolean
}

const SidebarWrapper = styled.div<SidebarWrapperProps>(
  ({ isOpen, hasActiveMarker }) => css`
    z-index: 1337;
    position: relative;
    height: 0;
    overflow: hidden;
    min-width: 320px;

    ${hasActiveMarker &&
    css`
      height: 32.8vh;
    `}

    ${isOpen &&
    css`
      height: 100vh;
    `}

    @media only screen and (min-width: ${breakpoints.tablet}) {
      width: 38vw;
      height: 100vh;
      display: ${isOpen ? "block" : "none"};
    }
  `
)

const SidebarContent = styled.div`
  padding: ${sizes.xl};
  width: 100%;
  height: 100%;
  overflow: scroll;
`

const CloseButton = styled(Button)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`
const ToggleOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  cursor: pointer;
  background: linear-gradient(
    to bottom,
    rgba(2, 0, 75, 0) 0%,
    rgba(2, 0, 75, 0) 32%,
    rgba(2, 0, 75, 1) 90%,
    rgba(2, 0, 75, 1) 100%
  );
`

export default function Sidebar() {
  const { currentPosition, activeMarker, setActiveMarker } =
    useContext(MapStateContext)
  const viewportWidth = useWindowWidth()
  const [isTablet, setIsTablet] = useState(
    viewportWidth >= parseInt(breakpoints.tablet)
  )
  const sidebarRef = useRef(null)
  const [isOpen, setIsOpen] = useState(true)

  const { trackEvent } = useMatomo()

  function closeSidebar() {
    setIsOpen(false)
  }

  function openSidebar() {
    console.log("actual open")
    setIsOpen(true)
  }

  // Enable marker when user moves to a marker position
  useEffect(() => {
    const currentMarker = markers.find(
      marker => marker.imageKey === currentPosition.imageKey
    )
    if (currentMarker && currentMarker.imageKey !== activeMarker.imageKey) {
      setActiveMarker(currentMarker)
    }
    if (isTablet) {
      setIsOpen(true)
    }
  }, [currentPosition, setActiveMarker, isTablet, activeMarker])

  // track marker view
  useEffect(() => {
    if (activeMarker) {
      trackEvent({
        category: "markers",
        action: "view",
        name: activeMarker.title,
      })
    }
  }, [activeMarker, trackEvent])

  // Check if we have tablet screen size to open sidebar directly
  useEffect(() => {
    setIsTablet(viewportWidth >= parseInt(breakpoints.tablet))
  }, [viewportWidth])

  // Force recalc of layout when sidebar status changes
  useEffect(() => {
    window.dispatchEvent(new Event("resize"))
    if (!isOpen) {
      sidebarRef.current.scrollTo(0, 0)
    }
  }, [isOpen, sidebarRef])

  const hasActiveMarker = useMemo(() => !!activeMarker, [activeMarker])

  return (
    <SidebarWrapper isOpen={isOpen} hasActiveMarker={hasActiveMarker}>
      {isOpen ? (
        <CloseButton width="auto" onClick={closeSidebar}>
          x
        </CloseButton>
      ) : (
        <ToggleOverlay onClick={openSidebar} />
      )}
      <SidebarContent ref={sidebarRef}>
        {activeMarker && (!isTablet || (isTablet && isOpen)) && (
          <activeMarker.content />
        )}
        <Gap size="l" />
        <Button background="primary" color="inverted" onClick={closeSidebar}>
          schließen
        </Button>
      </SidebarContent>
    </SidebarWrapper>
  )
}
