const breakpoints = {
  tablet: "724px",
  desktop: "1024px",
}

const fonts = {
  title: "Roboto",
  headline: "Roboto",
  body: "Roboto",
}

const colors: { [key: string]: string } = {
  default: "#fff",
  inverted: "#1a1a1a",
  background: "#02004B",
  primary: "#DBFF00",
  highlighted: "#fff",
  gray: "#CCCCCC",
}

const sizes = {
  xxs: "0.236rem",
  xs: "0.382rem",
  s: "0.618rem",
  m: "1rem",
  l: "1.618rem",
  xl: "2.618rem",
  xxl: "4.236rem",
}

export { breakpoints, colors, fonts, sizes }
