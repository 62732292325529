import styled from "@emotion/styled"

type ContainerProps = {}

const Container = styled.div<ContainerProps>`
  position: relative;
  max-width: 900px;
  margin: auto;
`

export default Container
