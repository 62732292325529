import styled from "@emotion/styled";
import { getColor } from "./";
import { colors } from "../config";

type ButtonProps = {
  background?: string;
  color?: string;
  width?: string;
  mode?: string;
  disabled?: boolean;
};

const Button = styled.div<ButtonProps>`
  opacity: ${(props) => (props.disabled ? "0.6" : "1.0")};
  display: inline-block;
  width: ${(props) => props.width || "100%"};
  padding: 1rem;
  background: ${(props) => getColor(props.background) || "transparent"};
  border-radius: 1rem;
  text-align: center;
  color: ${(props) => getColor(props.color) || colors.default};

  & svg {
    width: 1em;
  }

  &:hover {
    cursor: pointer;
  }
`;

export default Button;
