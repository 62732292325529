import { css } from "@emotion/core"
import styled from "@emotion/styled"
import "mapillary-js/dist/mapillary.css"
import propTypes from "prop-types"
import React from "react"
import { breakpoints } from "../../config"
import MapillaryViewer from "./react-mapillary"

const MapillaryContainer = styled.div(
  ({ enabled }) => css`
    display: ${enabled ? "block" : "none"};
    z-index: 10;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    overflow: hidden;

    @media only screen and (min-width: ${breakpoints.tablet}) {
      ${!enabled &&
      css`
        display: block;
        left: 5vmin;
        bottom: 5vmin;
        width: 32vmin;
        height: 24vmin;
        max-width: 320px;
        max-height: 240px;
        z-index: 20;
        font-size: 0.6em;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
          0 2px 4px -1px rgba(0, 0, 0, 0.06);
      `}
    }

    @media only screen and (min-width: ${breakpoints.desktop}) {
      ${!enabled &&
      css`
        max-height: 300px;
        max-width: 400px;
        width: 40vmin;
        height: 30vmin;
      `}
    }

    canvas {
      left: 0;
      top: 0;

      &:focus {
        outline: none;
      }
    }
  `
)

const Mapillary = ({ enabled }) => {
  return (
    <MapillaryContainer enabled={enabled}>
      <MapillaryViewer />
    </MapillaryContainer>
  )
}

Mapillary.propTypes = {
  enabled: propTypes.bool,
}

export default Mapillary
