import styled from "@emotion/styled"
import React, { useState } from "react"
import { Switch } from "../"
import { breakpoints } from "../../config"
import { MapStateProvider } from "../../contexts/map-state"
import Mapbox from "../Mapbox"
import Mapillary from "../Mapillary"
import Sidebar from "./Sidebar"

const MapContainer = styled.div`
  display: flex;
  width: 100vw;
  min-height: 100vh;
  flex-direction: column;
  @media only screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
  }
`
const MapWrapper = styled.div`
  position: relative;
  flex: 1 1 auto;
`

type MapProps = {}

const Map: React.FC<MapProps> = () => {
  const [mapIs360, set360] = useState(false)

  function toggleMap() {
    set360(!mapIs360)
    window.setTimeout(() => {
      window.dispatchEvent(new Event("resize"))
    }, 0)
  }

  return (
    <MapStateProvider>
      <MapContainer>
        <MapWrapper className="wrapper">
          <Switch
            isOn={mapIs360}
            onChange={toggleMap}
            labelOn="2D Karte"
            labelOff="3D Karte"
          />
          <Mapillary enabled={mapIs360} />
          <Mapbox enabled={!mapIs360} />
        </MapWrapper>
        <Sidebar />
      </MapContainer>
    </MapStateProvider>
  )
}

export default Map
