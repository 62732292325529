import styled from "@emotion/styled"
import { breakpoints, colors, sizes } from "../../config"

const SwitchWrapper = styled.div`
  position: absolute;
  z-index: 100;
  left: 50%;
  bottom: ${sizes.m};
  transform: translateX(-50%);

  padding: ${sizes.xxs} ${sizes.s};

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${colors.background};
  font-size: 0.8em;
  white-space: nowrap;

  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);

  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 1em;
    bottom: auto;
    top: 60px;
  }

  &:hover {
    cursor: pointer;
  }
`

export default SwitchWrapper
