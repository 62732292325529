import React from "react"

import { sizes } from "../config"

import logoBerlin from "../assets/logo-berlin.png"
import logoBundesstiftung from "../assets/logo-bundesstiftung.jpg"
import logoStiftungBerlinerMauer from "../assets/logo-stiftung-berliner-mauer.svg"

import {
  Align,
  Container,
  Gap,
  Headline,
  Image,
  Padding,
  Text,
  Title,
} from "../techboi-web-suite"

const Imprint: React.FC<{}> = () => {
  return (
    <Container>
      <Padding size={sizes.m}>
        <Align align="left">
          <Gap size="xl" />

          <Title>Impressum</Title>

          <Text>
            Trägerin der East Side Gallery ist die Stiftung Berliner Mauer.
          </Text>

          <Gap size="s" />

          <Text>
            Stiftung Berliner Mauer
            <br />
            Bernauer Straße 111
            <br />
            13355 Berlin
          </Text>

          <Gap size="s" />

          <Text margin="0">Telefon: +49 (0)30 213085-162</Text>
          <Text margin="0">Telefax: +49 (0)30 213085-162</Text>
          <Text>Email: info@stiftung-berliner-mauer.de</Text>

          <Gap size="s" />

          <Text>
            Die Stiftung Berliner Mauer ist eine Stiftung des öffentlichen
            Rechts.
          </Text>

          <Gap size="l" />

          <Headline>Vertretungsberechtigter Vorstand</Headline>
          <Text>Prof. Dr. Axel Klausmeier</Text>

          <Gap size="l" />

          <Headline>Zuständige Aufsichtsbehörde</Headline>
          <Text>
            Senatsverwaltung für Kultur und Europa
            <br />
            Brunnenstr. 188-190
            <br />
            10119 Berlin
          </Text>

          <Gap size="m" />

          <Text>
            Inhaltlich verantwortlich gemäß § 55 Abs. 2 RStV:
            <br />
            Prof. Dr. Axel Klausmeier
            <br />
            (Anschrift wie oben)
          </Text>

          <Gap size="l" />

          <Headline>Urheberrecht</Headline>

          <Text>
            Die grafische Gestaltung, die einzelnen Elemente sowie die Inhalte
            der Webseite sind urheberrechtlich geschützt. Dies gilt auch für die
            redaktionellen Beiträge, deren Auswahl und Zusammenstellung.
            Weiterverwendung und Vervielfältigung sind nur zu privaten Zwecken
            gestattet.
          </Text>

          <Gap size="l" />

          <Headline>Redaktion</Headline>

          <Text>
            Svenja Heißner, Helena Kürten, Cornelia Thiele, Anna v.
            Arnim-Rosenthal
          </Text>

          <Gap size="l" />

          <Headline>Mitwirkung</Headline>

          <Text>
            Nina Grabowski, Thomas Erbel, Elisa Pavin, Christoph Verbrüggen
          </Text>

          <Gap size="l" />

          <Headline>Dank</Headline>
          <Text>
            Herzlichen Dank an Teresa Casanueva, Kiddy Citny, Jeanett Kipka,
            Gábor Simon
          </Text>

          <Gap size="l" />
          <Headline>Kontakt</Headline>

          <Text>East Side Gallery</Text>
          <Text>eastsidegallery@stiftung-berliner.mauer.de</Text>

          <Gap size="l" />

          <Headline>Hinweis</Headline>
          <Text>
            Diese Basisversion der Tour-App East Side Gallery entstand im Rahmen
            des Veranstaltungsprogramms zum 30. Jahrestag der East Side Gallery.
            Die Stiftung Berliner Mauer strebt an, sie mit weiteren Fotos,
            Videos und Podcasts auszubauen.Stand: September 2020{" "}
          </Text>

          <Gap size="l" />

          <Headline>Technische Umsetzung</Headline>

          <Text>
            3D-Fotografie, Software und Design von{" "}
            <a href="https://hashbite.net">hashbite</a>.
          </Text>

          <Gap size="l" />

          <Image
            width="200px"
            src={logoStiftungBerlinerMauer}
            alt="Stiftung Berliner Mauer"
          />

          <Gap size="m" />

          <Text>
            Gefördert mit Mitteln der Bundesstiftung zur Aufarbeitung der
            SED-Diktatur
          </Text>

          <Gap size="m" />

          <Image
            width="200px"
            src={logoBundesstiftung}
            alt="Bundesstiftung Aufarbeitung"
          />

          <Gap size="m" />

          <Image width="200px" src={logoBerlin} alt="Berlin" />
        </Align>
      </Padding>
    </Container>
  )
}

export default Imprint
