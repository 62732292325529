import styled from "@emotion/styled"
import { sizes } from "../config"
import { getSize } from "./"

type TitleProps = {
  size?: string
}

const Title = styled.h1<TitleProps>`
  line-height: 1.2em;
  margin-bottom: ${sizes.l};
  font-size: ${props => getSize(props.size || "l")};
  font-weight: 700;
`

export default Title
