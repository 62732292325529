import React from "react"
import ReactModal from "react-modal"
import ModalWrapper from "./Modal.style"
import { Button } from "../"
import { colors } from "../../config"

type ModalProps = {
  isOpen: boolean
  onRequestClose?: () => void
  hideClose?: boolean
}

const customStyles = {
  overlay: {
    overflow: "scroll",
    backgroundColor: "rgba(0,0,0,0.9)",
    zIndex: 9999,
  },
  content: {
    top: "10vh",
    bottom: "auto",
    left: "50%",
    width: "100vw",
    maxWidth: "800px",
    // right: "auto",
    transform: "translateX(-50%)",
    background: `${colors.background}`,
    border: "0",
    borderRadius: "0",
    color: `${colors.default}`,
    boxShadow: `0 25px 50px -12px rgba(0, 0, 0, 0.25)`,
  },
}

ReactModal.setAppElement("#root")

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onRequestClose,
  hideClose,
  children,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
    >
      {!hideClose && (
        <Button width="auto" onClick={onRequestClose}>
          x
        </Button>
      )}
      <ModalWrapper>
        <div>{children}</div>
      </ModalWrapper>
    </ReactModal>
  )
}

export default Modal
