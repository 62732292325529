import React, { useEffect, useRef, useState, useContext } from "react"
import PropTypes from "prop-types"
import { Viewer } from "mapillary-js"

import { sequences } from "../../config/markers"
import { MapStateContext } from "../../contexts/map-state"

const sequenceFilter = ["in", "sequenceId", ...sequences]

export default function MapillaryViewer({ filter }) {
  const { currentPosition, setCurrentPosition } = useContext(MapStateContext)
  const [viewer, setViewer] = useState(undefined)

  const containerRef = useRef()
  // Init mapillary
  useEffect(() => {
    if (!containerRef.current || viewer) {
      return
    }

    const mapillaryOptions = {
      accessToken: process.env.REACT_APP_MAPILLARY_CLIENT_TOKEN,
      container: containerRef.current,
      combinedPanning: true,
      component: {
        cover: false,
        cache: true,
        direction: true,
      },
    }

    if (typeof imageKey === "number") {
      mapillaryOptions.imageId = currentPosition.imageKey
    }

    console.log("init mapillary", {
      container: containerRef.current,
      viewer,
      mapillaryOptions,
    })

    const viewerInstance = new Viewer(mapillaryOptions)

    // Initialize with .moveToKey because passing the imageKey in the constructor
    // does not update the viewer until the user clicks explore
    viewerInstance.on("image", event => {
      const { originalLngLat, id } = event.image
      const location = [originalLngLat.lng, originalLngLat.lat]
      setCurrentPosition({ imageKey: parseInt(id), location })
    })

    viewerInstance.setFilter(sequenceFilter)

    setViewer(viewerInstance)

    return function cleanup() {
      console.log("unmount mapillary")
      // viewerInstance.remove()
    }
  }, [filter, currentPosition.imageKey, setCurrentPosition, viewer])

  // move viewer when active image changes
  useEffect(() => {
    async function moveAndRotate() {
      await viewer.moveTo(currentPosition.imageKey)
      await viewer.setCenter([0.5, 0.5])
    }
    if (
      viewer &&
      currentPosition &&
      viewer.isNavigable &&
      typeof currentPosition.imageKey === "number"
    ) {
      moveAndRotate()
    }
  }, [currentPosition, viewer])

  return <div ref={containerRef} style={{ width: "100%", height: "100%" }} />
}

MapillaryViewer.propTypes = {
  currentPosition: PropTypes.object,
  filter: PropTypes.array,
  setCurrentPosition: PropTypes.func,
}

MapillaryViewer.defaultProps = {
  filter: null,
}
