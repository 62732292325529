import styled from "@emotion/styled"

import { sizes } from "../config"

type HeadlineProps = {
  size?: string
}

const Headline = styled.h2<HeadlineProps>`
  line-height: 1.15em;
  margin-bottom: ${sizes.s};
  font-size: ${props => props.size || "20px"};
  font-weight: 700;
`

export default Headline
